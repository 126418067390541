import { defaultWagmiConfig } from '@web3modal/wagmi'
import { http } from '@wagmi/core'
import { mainnet, bsc } from '@wagmi/core/chains'
import logo from "@/assets/image_img/logo.png"

// 1. Define constants
const projectId = 'b4e41353f250958b0c77472ed603222a'
// 2. Create wagmiConfig
const metadata = {
  name: 'DeepLink',
  description: 'DeepLink',
  url: 'https://www.deeplink.cloud/',
  // url: 'http://localhost:8099/',
  icons: [logo]
}
const chains = [
  // mainnet, 
  bsc
]

const data_con = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  transports: {
    // [mainnet.id]: http(),
    [bsc.id]: http(),
  }
})

export default data_con