<template>
  <div class="BandWidth">
    <div class="home_bg"></div>
    <div class="page_cont1">
      <div class="title animation_hide" v-animate="{delay: 0, class:'amplify'}">
        <p>{{ $t('BandWidth.title') }}</p>
      </div>
      <div class="text animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('BandWidth.text1') }}</div>
    </div>
    <div class="page_cont2 delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
      <div class="title">{{ $t('BandWidth.cont2.title') }}</div>
      <div class="text">{{ $t('BandWidth.cont2.text1_1') }} <a href="https://www.deeplink.cloud/node" target="_blank">https://www.deeplink.cloud/node</a> {{ $t('BandWidth.cont2.text1_2') }}</div>
      <div class="text1">{{ $t('BandWidth.cont2.text2_1') }}</div>
      <div class="btns">
        <div class="btn">
          <p class="btn_title">{{ $t('BandWidth.cont2.text3_1') }}</p>
          <p class="btn_text">{{ $t('BandWidth.cont2.text3_2') }}</p>
        </div>
        <div class="btn">
          <p class="btn_title">{{ $t('BandWidth.cont2.text4_1') }}</p>
          <p class="btn_text">{{ $t('BandWidth.cont2.text4_2') }}</p>
        </div>
      </div>
      <div class="require">
        <div class="req_text1">
          <p class="text_top">{{ $t('BandWidth.cont2.text5_1') }}</p>
          <p class="text_bottom">{{ $t('BandWidth.cont2.text5_2') }}</p>
        </div>
        <div class="req_text1">
          <p class="text_top">{{ $t('BandWidth.cont2.text6_1') }}</p>
          <p class="text_bottom">{{ $t('BandWidth.cont2.text6_2') }}</p>
        </div>
      </div>
    </div>
    <div class="page_cont3">
      <div class="page3_bg"></div>
      <div class="title delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">{{ $t('BandWidth.cont3.title') }}</div>
      <div class="text delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('BandWidth.cont3.text') }}</div>
      <div class="text delay500 animation_hide" v-animate="{delay: 500, class:'fadeInUp'}">{{ $t('BandWidth.cont3.text1') }}</div>
      <div class="page_cont delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th colspan="4" class="">{{ $t('BandWidth.cont3.text2') }}</th>
            </tr>
            <tr>
              <td colspan="2" class="font20">{{ $t('BandWidth.cont3.li1') }}</td>
              <td colspan="2" class="font20">{{ $t('BandWidth.cont3.li_other') }}</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_1') }}</td>
              <td class="">50000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_4') }}</td>
              <td class="">3000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_2') }}</td>
              <td class="">15000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_5') }}</td>
              <td class="">2000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_3') }}</td>
              <td class="">60000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_6') }}</td>
              <td class="">2000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_4') }}</td>
              <td class="">50000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_7') }}</td>
              <td class="">50000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_5') }}</td>
              <td class="">50000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_8') }}</td>
              <td class="">1000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_6') }}</td>
              <td class="">40000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_9') }}</td>
              <td class="">3000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_7') }}</td>
              <td class="">20000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_10') }}</td>
              <td class="">5000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_8') }}</td>
              <td class="">3000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_11') }}</td>
              <td class="">8000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li1_9') }}</td>
              <td class="">1000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_12') }}</td>
              <td class="">50000</td>
            </tr>
            <tr>
              <td colspan="2" class="font20">{{ $t('BandWidth.cont3.li2') }}</td>
              <td class="">{{ $t('BandWidth.cont3.li2_13') }}</td>
              <td class="">3000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li2_1') }}</td>
              <td class="">2000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_14') }}</td>
              <td class="">6000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li2_2') }}</td>
              <td class="">1000</td>
              <td class="">{{ $t('BandWidth.cont3.li2_15') }}</td>
              <td class="">2000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li2_3') }}</td>
              <td class="">1000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="page_cont delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th colspan="4" class="">{{ $t('BandWidth.cont3.text3') }}</th>
            </tr>
            <tr>
              <td colspan="2" class="font20">{{ $t('BandWidth.cont3.li3') }}</td>
              <td colspan="2" class="font20">{{ $t('BandWidth.cont3.li_other') }}</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_1') }}</td>
              <td class="">8000</td>
              <td class="">{{ $t('BandWidth.cont3.li4_1') }}</td>
              <td class="">2000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_2') }}</td>
              <td class="">6000</td>
              <td class="">{{ $t('BandWidth.cont3.li4_2') }}</td>
              <td class="">3000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_3') }}</td>
              <td class="">4000</td>
              <td class="">{{ $t('BandWidth.cont3.li4_3') }}</td>
              <td class="">5000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_4') }}</td>
              <td class="">4000</td>
              <td class="">{{ $t('BandWidth.cont3.li4_4') }}</td>
              <td class="">1000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_5') }}</td>
              <td class="">3000</td>
              <td class="">{{ $t('BandWidth.cont3.li4_5') }}</td>
              <td class="">2000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_6') }}</td>
              <td class="">3000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_7') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_8') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_9') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_10') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li3_11') }}</td>
              <td class="">8000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="page_cont delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th colspan="4" class="">{{ $t('BandWidth.cont3.text4') }}</th>
            </tr>
            <tr>
              <td colspan="4" class="font20">{{ $t('BandWidth.cont3.li5') }}</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_1') }}</td>
              <td class="">2000</td>
              <td class="">{{ $t('BandWidth.cont3.li5_2') }}</td>
              <td class="">1000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_3') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td colspan="4" class="font20">{{ $t('BandWidth.cont3.li_other') }}</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_4') }}</td>
              <td class="">9000</td>
              <td class="">{{ $t('BandWidth.cont3.li5_5') }}</td>
              <td class="">9000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_6') }}</td>
              <td class="">9000</td>
              <td class="">{{ $t('BandWidth.cont3.li5_7') }}</td>
              <td class="">6000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_8') }}</td>
              <td class="">6000</td>
              <td class="">{{ $t('BandWidth.cont3.li5_9') }}</td>
              <td class="">5000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li5_10') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="page_cont delay100 animation_hide" v-animate="{delay: 100, class:'fadeInUp'}">
        <div class="cont_table">
          <table class="table_cont">
            <tr class="theader">
              <th colspan="2" class="">{{ $t('BandWidth.cont3.text5') }}</th>
              <th colspan="2" class="">{{ $t('BandWidth.cont3.text6') }}</th>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li6_1') }}</td>
              <td class="">2000</td>
              <td class="">{{ $t('BandWidth.cont3.li7_1') }}</td>
              <td class="">5000</td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li6_2') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
            <tr>
              <td class="">{{ $t('BandWidth.cont3.li6_3') }}</td>
              <td class="">2000</td>
              <td class=""></td>
              <td class=""></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="page_cont5 delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
      <div class="title">{{ $t('BandWidth.cont5.title') }}</div>
      <div class="text">{{ $t('BandWidth.cont5.text1') }}</div>
      <div class="text">
        <p>{{ $t('BandWidth.cont5.text2') }}</p>
        <p>{{ $t('BandWidth.cont5.text3') }}</p>
      </div>
      <div class="text">
        <p>{{ $t('BandWidth.cont5.text4') }}</p>
        <p>{{ $t('BandWidth.cont5.text5') }}</p>
        <p>{{ $t('BandWidth.cont5.text6') }}</p>
        <p>{{ $t('BandWidth.cont5.text7') }}</p>
        <p>{{ $t('BandWidth.cont5.text8') }}</p>
        <p>{{ $t('BandWidth.cont5.text9') }}</p>
        <p>{{ $t('BandWidth.cont5.text10') }}</p>
        <p>{{ $t('BandWidth.cont5.text11') }}</p>
        <p>{{ $t('BandWidth.cont5.text12') }}</p>
      </div>
      <div class="text">
        <p>{{ $t('BandWidth.cont5.text13') }}</p>
        <p>{{ $t('BandWidth.cont5.text14') }}</p>
        <p>P<sub>i</sub>：{{ $t('BandWidth.cont5.text15') }}</p>
        <p>H<sub>i</sub>：{{ $t('BandWidth.cont5.text16') }}</p>
        <p>T<sub>i</sub>：{{ $t('BandWidth.cont5.text17') }}</p>
      </div>
      <div class="text">
        <div class="formula">
          <span>B<sub>i</sub> = </span>
          <div class="fraction">
            <div class="numerator">H<sub>i</sub>&nbsp;&middot;&nbsp;T<sub>i</sub>&nbsp;&middot;&nbsp;ln(max(10000, P<sub style="font-size:12px">i</sub>))</div>
            <div class="denominator">
                &sum;<sup>N</sup><sub style="margin-left: -10px;">j=1</sub> H<sub>j</sub> &middot; T<sub>j</sub>&nbsp;&middot;&nbsp;ln(max(10000, P<sub style="font-size:12px">j</sub>))
            </div>
          </div>
          <span class="product"> <span>·</span> R</span>
        </div>
      </div>
      <div class="text">
        <p>{{ $t('BandWidth.cont5.text18') }}</p>
        <p>{{ $t('BandWidth.cont5.text19') }}</p>
        <p>{{ $t('BandWidth.cont5.text20') }}</p>
        <p>{{ $t('BandWidth.cont5.text21') }}</p>
        <p>{{ $t('BandWidth.cont5.text22') }}</p>
      </div>
      <div class="title1">{{ $t('BandWidth.cont5.title1') }}</div>
      <div class="text">
        <!-- <p>{{ $t('BandWidth.cont5.text1_1') }}</p>
        <p>{{ $t('BandWidth.cont5.text1_2') }}</p>
        <p>{{ $t('BandWidth.cont5.text1_3') }}</p>
        <p>{{ $t('BandWidth.cont5.text1_4') }}</p> -->
        <p>{{ $t('BandWidth.cont5.text1_5') }}</p>
      </div>
      <div class="title1">{{ $t('BandWidth.cont5.title2', {num: dlc_price}) }}</div>
      <div class="text2">
        <div class="text_select">
          <p class="text2_tx">{{ $t('BandWidth.cont5.text2_1') }}</p>
          <el-select class="el-select-type select_width210" v-model="bwvalue" :placeholder="$t('BandWidth.cont5.text2_7')" @change='SelectBW'>
            <el-option v-for="(item, index) in bandwidth " :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select> 
        </div>
        <div class="text_select">
          <p class="text2_tx">{{ $t('BandWidth.cont5.text2_2') }}</p>
          <el-select class="el-select-type select_width210" v-model="nft_num" :placeholder="$t('BandWidth.cont5.text2_7')" @change='SelectNftnum'>
            <el-option v-for="(item, index) in nft_num_list " :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select> 
        </div>
        <div class="text_select">
          <p class="text2_tx">{{ $t('BandWidth.cont5.text2_3') }}</p>
          <el-select filterable class="el-select-type select_width" v-model="local" :placeholder="$t('BandWidth.cont5.text2_7')" @change='SelectLocal'>
            <el-option-group
              v-for="group in local_data"
              :key="group.label"
              :label="group.label"
            >
              <el-option v-for="(item, index) in group.options " :key="item.label" :label="item.label" :value="item.label + '_' + item.value"></el-option>
          </el-option-group>
          </el-select> 
        </div>
        <div class="text_select">
          <p class="text2_tx">{{ $t('BandWidth.cont5.text2_4') }}</p>
          <el-select class="el-select-type select_width" v-model="stak_dlc_num" :placeholder="$t('BandWidth.cont5.text2_7')" @change='SelectStakDLC'>
            <el-option v-for="(item, index) in stak_dlc_list " :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="text_select">
          <p class="text2_tx">{{ $t('BandWidth.cont5.text2_5') }}</p>
          <el-select class="el-select-type select_width210" v-model="totalbwvalue" :placeholder="$t('BandWidth.cont5.text2_7')" @change='SelectTotalBW'>
            <el-option v-for="(item, index) in total_bandwidth " :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select> 
        </div>
      </div>
      <div class="text">{{ $t('BandWidth.cont5.text2_6') }}</div>
      <div class="title1">{{ $t('BandWidth.cont5.title3', {dlc_num: dlc_earning, usdt_num: dlc_usdt_earning, num: dlc_price }) }}</div>
      <div class="title title2">{{ $t('BandWidth.cont5.title4') }}</div>
      <div class="text">{{ $t('BandWidth.cont5.text4_1') }}</div>
      <div class="text">
        <p>{{ $t('BandWidth.cont5.text4_2') }}</p>
        <p>{{ $t('BandWidth.cont5.text4_3') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onBeforeMount, onBeforeUnmount, onMounted, getCurrentInstance, watch, nextTick } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex" 
  import { getDLCPrice } from "@/api/index.js"
  import { useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    name: 'BandWidth',
    setup() {
      const store = useStore()
      const router = useRouter()
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const instance = getCurrentInstance()
      // 计算DLC每天DLC收益
      const dlc_price = ref(0)
      const bwvalue = ref('')
      const nft_num = ref('')
      const local = ref('')
      const refresh = ref(undefined)
      const stak_dlc_num = ref('')
      const totalbwvalue = ref('')
      const nft_num_list = ref([
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
        {label: 6, value: 6},
        {label: 7, value: 7},
        {label: 8, value: 8},
        {label: 9, value: 9},
        {label: 10, value: 10},
        {label: 11, value: 11},
        {label: 12, value: 12},
        {label: 13, value: 13},
        {label: 14, value: 14},
        {label: 15, value: 15},
        {label: 16, value: 16},
        {label: 17, value: 17},
        {label: 18, value: 18},
        {label: 19, value: 19},
        {label: 20, value: 20}
      ])
      const stak_dlc_list = ref([
        {label: '0', value: 1},
        {label: '10,000', value: 10000},
        {label: '50,000', value: 20000},
        {label: '100,000', value: 100000},
        {label: '200,000', value: 200000},
        {label: '500,000', value: 500000},
        {label: '1,000,000', value: 1000000},
        {label: '5,000,000', value: 5000000},
        {label: '10,000,000', value: 10000000}
      ])

      const bandwidth = ref([
        { label: '100Mb', value: 100 },
        { label: '200Mb', value: 200 },
        { label: '400Mb', value: 400 },
        { label: '1Gb', value: 1000 },
        { label: '2Gb', value: 2000 },
        { label: '4Gb', value: 4000 },
        { label: '10Gb', value: 10000 },
      ])
      
      const total_bandwidth = ref([
        { label: '1Gb', value: 1000 },
        { label: '2Gb', value: 2000 },
        { label: '4Gb', value: 4000 },
        { label: '10Gb', value: 10000 },
        { label: '20Gb', value: 20000 },
        { label: '40Gb', value: 40000 },
        { label: '100Gb', value: 100000 },
      ])
      const local_data = ref([
        {
          label: t('BandWidth.cont3.text2'),
          options: [
            { label: t('BandWidth.cont3.li1_1'), value: 50000 },
            { label: t('BandWidth.cont3.li1_2'), value: 15000 },
            { label: t('BandWidth.cont3.li1_3'), value: 60000 },
            { label: t('BandWidth.cont3.li1_4'), value: 50000 },
            { label: t('BandWidth.cont3.li1_5'), value: 50000 },
            { label: t('BandWidth.cont3.li1_6'), value: 40000 },
            { label: t('BandWidth.cont3.li1_7'), value: 20000 },
            { label: t('BandWidth.cont3.li1_8'), value: 3000 },
            { label: t('BandWidth.cont3.li1_9'), value: 1000 },

            { label: t('BandWidth.cont3.li2_1'), value: 2000},
            { label: t('BandWidth.cont3.li2_2'), value: 1000},
            { label: t('BandWidth.cont3.li2_3'), value: 1000},
            { label: t('BandWidth.cont3.li2_4'), value: 3000},
            { label: t('BandWidth.cont3.li2_5'), value: 2000},
            { label: t('BandWidth.cont3.li2_6'), value: 2000},
            { label: t('BandWidth.cont3.li2_7'), value: 50000},
            { label: t('BandWidth.cont3.li2_8'), value: 1000},
            { label: t('BandWidth.cont3.li2_9'), value: 3000},
            { label: t('BandWidth.cont3.li2_10'), value: 5000},
            { label: t('BandWidth.cont3.li2_11'), value: 8000},
            { label: t('BandWidth.cont3.li2_12'), value: 50000},
            { label: t('BandWidth.cont3.li2_13'), value: 3000},
            { label: t('BandWidth.cont3.li2_14'), value: 6000},
            { label: t('BandWidth.cont3.li2_15'), value: 2000},
          ],
        },
        {
          label: t('BandWidth.cont3.text3'),
          options: [
            { label: t('BandWidth.cont3.li3_1'), value: 8000},
            { label: t('BandWidth.cont3.li3_2'), value: 6000},
            { label: t('BandWidth.cont3.li3_3'), value: 4000},
            { label: t('BandWidth.cont3.li3_4'), value: 4000},
            { label: t('BandWidth.cont3.li3_5'), value: 3000},
            { label: t('BandWidth.cont3.li3_6'), value: 3000},
            { label: t('BandWidth.cont3.li3_7'), value: 2000},
            { label: t('BandWidth.cont3.li3_8'), value: 2000},
            { label: t('BandWidth.cont3.li3_9'), value: 2000},
            { label: t('BandWidth.cont3.li3_10'), value: 2000},
            { label: t('BandWidth.cont3.li3_11'), value: 8000},
            
            { label: t('BandWidth.cont3.li4_1'), value: 2000},
            { label: t('BandWidth.cont3.li4_2'), value: 3000},
            { label: t('BandWidth.cont3.li4_3'), value: 5000},
            { label: t('BandWidth.cont3.li4_4'), value: 1000},
            { label: t('BandWidth.cont3.li4_5'), value: 2000},
          ],
        },
        {
          label: t('BandWidth.cont3.text4'),
          options: [
            { label: t('BandWidth.cont3.li5_1'), value: 2000},
            { label: t('BandWidth.cont3.li5_2'), value: 1000},
            { label: t('BandWidth.cont3.li5_3'), value: 2000},
            { label: t('BandWidth.cont3.li5_4'), value: 9000},
            { label: t('BandWidth.cont3.li5_5'), value: 9000},
            { label: t('BandWidth.cont3.li5_6'), value: 9000},
            { label: t('BandWidth.cont3.li5_7'), value: 6000},
            { label: t('BandWidth.cont3.li5_8'), value: 6000},
            { label: t('BandWidth.cont3.li5_9'), value: 5000},
            { label: t('BandWidth.cont3.li5_10'), value: 2000},
            
          ],
        },
        {
          label: t('BandWidth.cont3.text5'),
          options: [
            { label: t('BandWidth.cont3.li6_1'), value: 2000},
            { label: t('BandWidth.cont3.li6_2'), value: 2000},
            { label: t('BandWidth.cont3.li6_3'), value: 2000},
          ],
        },
        {
          label: t('BandWidth.cont3.text6'),
          options: [
            { label: t('BandWidth.cont3.li7_1'), value: 5000},
          ],
        },
      ])
      const regional = ref(0)
      const SelectBW = (val) => {
        console.log(val);
        DayDLCIncome()
      }
      const SelectNftnum = (val) => {
        console.log(val);
        DayDLCIncome()
      }
      const SelectLocal = (val) => {
        console.log(val);
        const arr = val.split('_')
        console.log(arr[1]);
        regional.value = Number(arr[1])
        DayDLCIncome()
      }
      const SelectStakDLC = (val) => {
        console.log(val);
        DayDLCIncome()
      }
      const SelectTotalBW = (val) => {
        console.log(val);
        DayDLCIncome()
      }
      const dlc_earning = ref(0)
      const dlc_usdt_earning = ref(0)
      
      const DayDLCIncome = () => {
        if (bwvalue.value&&nft_num.value&&local.value&&stak_dlc_num.value&&totalbwvalue.value) {
          // 计算奖励
          const total_regional = 547000
          const day_reward_dlc = 1369000

          const reward_token = regional.value * day_reward_dlc/ total_regional
          // 非线性ln
          const result1 = Math.log(Math.max(10000, stak_dlc_num.value == 1 ? 10000 : stak_dlc_num.value))
          const result = Math.log(Math.max(10000, 10000))
          const top_num = bwvalue.value * 86400 * nft_num.value * result1
          const bottom_num = totalbwvalue.value * 86400 * 10 * result
          dlc_earning.value = ((top_num / bottom_num) * reward_token).toFixed(4)
          dlc_usdt_earning.value = (((top_num / bottom_num) * reward_token) * dlc_price.value).toFixed(2)
        }
      }

      const getData = async () => {
        const data = await getDLCPrice()
        if (data.content) {
          console.log(data, 'data');
          dlc_price.value = data.content.dlc_price
        }
      }

      onMounted(async () => {
        if (refresh.value) {
          clearInterval(refresh.value)
          refresh.value = null
        }
        await getData()
        await nextTick();
        refresh.value = setInterval( async () => {
          await getData()
        }, 30000)
      })
      onBeforeUnmount(() => {
        if (refresh.value) {
          clearInterval(refresh.value)
          refresh.value = null
        }
      })
      watch(
        () => locale.value,
        () => {
          local_data.value = [
            {
              label: t('BandWidth.cont3.text2'),
              options: [
                { label: t('BandWidth.cont3.li1_1'), value: 50000 },
                { label: t('BandWidth.cont3.li1_2'), value: 15000 },
                { label: t('BandWidth.cont3.li1_3'), value: 60000 },
                { label: t('BandWidth.cont3.li1_4'), value: 50000 },
                { label: t('BandWidth.cont3.li1_5'), value: 50000 },
                { label: t('BandWidth.cont3.li1_6'), value: 40000 },
                { label: t('BandWidth.cont3.li1_7'), value: 20000 },
                { label: t('BandWidth.cont3.li1_8'), value: 3000 },
                { label: t('BandWidth.cont3.li1_9'), value: 1000 },

                { label: t('BandWidth.cont3.li2_1'), value: 2000},
                { label: t('BandWidth.cont3.li2_2'), value: 1000},
                { label: t('BandWidth.cont3.li2_3'), value: 1000},
                { label: t('BandWidth.cont3.li2_4'), value: 3000},
                { label: t('BandWidth.cont3.li2_5'), value: 2000},
                { label: t('BandWidth.cont3.li2_6'), value: 2000},
                { label: t('BandWidth.cont3.li2_7'), value: 50000},
                { label: t('BandWidth.cont3.li2_8'), value: 1000},
                { label: t('BandWidth.cont3.li2_9'), value: 3000},
                { label: t('BandWidth.cont3.li2_10'), value: 5000},
                { label: t('BandWidth.cont3.li2_11'), value: 8000},
                { label: t('BandWidth.cont3.li2_12'), value: 50000},
                { label: t('BandWidth.cont3.li2_13'), value: 3000},
                { label: t('BandWidth.cont3.li2_14'), value: 6000},
                { label: t('BandWidth.cont3.li2_15'), value: 2000},
              ],
            },
            {
              label: t('BandWidth.cont3.text3'),
              options: [
                { label: t('BandWidth.cont3.li3_1'), value: 8000},
                { label: t('BandWidth.cont3.li3_2'), value: 6000},
                { label: t('BandWidth.cont3.li3_3'), value: 4000},
                { label: t('BandWidth.cont3.li3_4'), value: 4000},
                { label: t('BandWidth.cont3.li3_5'), value: 3000},
                { label: t('BandWidth.cont3.li3_6'), value: 3000},
                { label: t('BandWidth.cont3.li3_7'), value: 2000},
                { label: t('BandWidth.cont3.li3_8'), value: 2000},
                { label: t('BandWidth.cont3.li3_9'), value: 2000},
                { label: t('BandWidth.cont3.li3_10'), value: 2000},
                { label: t('BandWidth.cont3.li3_11'), value: 8000},
                
                { label: t('BandWidth.cont3.li4_1'), value: 2000},
                { label: t('BandWidth.cont3.li4_2'), value: 3000},
                { label: t('BandWidth.cont3.li4_3'), value: 5000},
                { label: t('BandWidth.cont3.li4_4'), value: 1000},
                { label: t('BandWidth.cont3.li4_5'), value: 2000},
              ],
            },
            {
              label: t('BandWidth.cont3.text4'),
              options: [
                { label: t('BandWidth.cont3.li5_1'), value: 2000},
                { label: t('BandWidth.cont3.li5_2'), value: 1000},
                { label: t('BandWidth.cont3.li5_3'), value: 2000},
                { label: t('BandWidth.cont3.li5_4'), value: 9000},
                { label: t('BandWidth.cont3.li5_5'), value: 9000},
                { label: t('BandWidth.cont3.li5_6'), value: 9000},
                { label: t('BandWidth.cont3.li5_7'), value: 6000},
                { label: t('BandWidth.cont3.li5_8'), value: 6000},
                { label: t('BandWidth.cont3.li5_9'), value: 5000},
                { label: t('BandWidth.cont3.li5_10'), value: 2000},
                
              ],
            },
            {
              label: t('BandWidth.cont3.text5'),
              options: [
                { label: t('BandWidth.cont3.li6_1'), value: 2000},
                { label: t('BandWidth.cont3.li6_2'), value: 2000},
                { label: t('BandWidth.cont3.li6_3'), value: 2000},
              ],
            },
            {
              label: t('BandWidth.cont3.text6'),
              options: [
                { label: t('BandWidth.cont3.li7_1'), value: 5000},
              ],
            },
          ]
        }
      )
      return {
        lan,
        // DLC收益字段
        bwvalue,
        nft_num,
        local,
        dlc_price,
        stak_dlc_num,
        totalbwvalue,
        bandwidth,
        nft_num_list,
        local_data,
        stak_dlc_list,
        total_bandwidth,
        dlc_earning,
        dlc_usdt_earning,
        SelectBW,
        SelectNftnum,
        SelectLocal,
        SelectStakDLC,
        SelectTotalBW
      };
    }
  })
</script> 

<style lang="scss" scoped>
.BandWidth {
  position: relative;
  z-index: 2;
  width: 100%;
  min-width: 600px;
  font-style: normal;
  overflow: hidden;
  .page {
    position: relative;
    z-index: 10;
    min-width: 600px;
  }
  .page_cont1 {
    position: relative;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    overflow: hidden;
    text-align: left;
    .title {
      color: #fff;
      line-height: 1;
      font-size: 40px;
      font-weight: 600;
      max-width: 1200px;
      margin-top: 100px;
      font-family: Monda;
      p {
        margin-bottom: 30px;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    .text {
      font-size: 24px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 100px;
      color: rgba(255, 255, 255, 0.5);
    }
    .text1 {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 70px;
    }
  }
  .page_cont2 {
    position: relative;
    z-index: 5;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 50px;
    align-items: center;
    justify-content: space-between;
    .title {
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .text {
      width: 100%;
      font-size: 16px;
      max-width: 1050px;
      font-family: Monda;
      margin-bottom: 70px;
      color: rgba(255, 255, 255, 0.6);
      & a {
        // text-decoration: none;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .text1 {
      width: 540px;
      color: #fff;
      font-size: 20px;
      line-height: 40px;
      font-family: Monda;
      margin-bottom: 90px;
      white-space: break-spaces;
    }
    .btns {
      flex: 1;
      display: flex;
      margin-bottom: 90px;
      justify-content: space-between;
      .btn {
        cursor: pointer;
        min-width: 260px;
        text-align: left;
        font-family: Monda;
        margin-right: 18px;
        padding: 20px 30px;
        border-radius: 16px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.1);
        &:last-child {
          margin-right: 0;
        }
        .btn_title {
          font-size: 20px;
          font-weight: 600;
          color: #00FF4D;
          margin-bottom: 10px;
        }
        .btn_text {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .require {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;
      justify-content: space-between;
      .req_text1 {
        width: 540px;
        margin-bottom: 30px;
        padding: 20px 30px;
        border-radius: 16px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.1);
      }
      .req_text2 {
        width: 260px;
        margin-bottom: 30px;
        padding: 20px 30px;
        border-radius: 16px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.1);
      }
      .text_top {
        // color: #fff;
        font-size: 20px;
        font-weight: 600;
        color: #00FF4D;
        font-family: Monda;
        margin-bottom: 20px;
      }
      .text_bottom {
        color: #fff;
        font-size: 16px;
        font-family: Monda;
        // color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .page_cont3 {
    position: relative;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: 70px;
    align-items: center;
    justify-content: space-between;
    .title {
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .text {
      width: 100%;
      font-size: 16px;
      font-family: Monda;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.6);
      & a {
        // text-decoration: none;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .text1 {
      width: 100%;
      font-size: 16px;
      font-family: Monda;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 1);
    }
    .page_cont {
      width: 100%;
      display: flex;
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 50px;
      flex-direction: column;
      .cont_table {
        width: 100%;
        border-radius: 20px;
        box-sizing: border-box;
        .table_cont {
          width: 100%;
          border-collapse: collapse;
          tr.theader {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            font-family: Monda;
            background: #03FF91;
            th {
              border: none;
              line-height: 1;
              padding: 10px 15px;
            }
          }
          th {
            text-align: center;
            padding: 0 15px;
          }
          td {
            width: 25%;
            font-size: 16px;
            font-family: Monda;
            padding: 10px 15px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.1);
            border-top: 3px solid #000;
            border-bottom: 0;
            &.num {
              color: #fff;
              font-weight: 600;
            }
            &.font20 {
              font-size: 18px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        .width270 {
          width: 270px;
        }
      }
    }
  }
  .page_cont4 {
    position: relative;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: 46px;
    align-items: center;
    justify-content: space-between;
    .title {
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .page_cont {
      width: 100%;
      display: flex;
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 70px;
      flex-direction: column;
      .cont_table {
        width: 100%;
        border-radius: 20px;
        box-sizing: border-box;
        .table_cont {
          width: 100%;
          text-align: left;
          border-collapse: collapse;
          tr.theader {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            font-family: Monda;
            background: #03FF91;
            th {
              border: none;
              padding: 10px 15px;
            }
          }
          th {
            text-align: center;
            padding: 0 15px;
          }
          td {
            font-size: 16px;
            font-family: Monda;
            padding: 10px 15px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.1);
            border-top: 3px solid #000;
            border-bottom: 0;
            &.num {
              color: #fff;
              font-weight: 600;
            }
          }
        }
        .width270 {
          width: 270px;
        }
      }
    }
    .text1 {
      width: 100%;
      color: #fff;
      font-size: 24px;
      font-family: Monda;
      margin-bottom: 24px;
    }
    .text2 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .text_select {
        display: flex;
        align-items: center;
      }
      .text2_tx {
        color: #fff;
        font-size: 20px;
        font-family: Monda;
        margin-bottom: 12px;
      }
      .select_width210 {
        width: 120px;
        margin: 0 20px 12px 10px;
      }
      
      .select_width {
        margin: 0 20px 12px 10px;
      }
    }
  }
  .page_cont5 {
    position: relative;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: center;
    margin: 0 auto 100px;
    justify-content: space-between;
    .title {
      width: 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .text {
      width: 100%;
      font-size: 16px;
      line-height: 30px;
      font-family: Monda;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.6);
    }
    .title1 {
      width: 100%;
      color: #fff;
      margin: 20px 0;
      font-size: 20px;
      font-family: Monda;
    }
    .title2 {
      margin-top: 50px;
    }
    .blockcon {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-family: Monda;
      margin: 90px 0 35px;
      justify-content: space-between;
      .block {
        // width: 49%;
        display: flex;
        padding: 40px;
        border-radius: 26px;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
      }
      .block1 {
        background: #03FF91;
      }
      .block2 {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.15);
        .top_title {
          color: #fff;
        }
        .top_text {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .top_title {
        width: 100%;
        color: #000;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .top_text {
        width: 100%;
        color: #000;
        font-size: 16px;
        word-break: break-all;
        color: rgba(0, 0, 0, 0.6);
        a {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .text2 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .text_select {
        display: flex;
        align-items: center;
      }
      .text2_tx {
        color: #fff;
        font-size: 20px;
        font-family: Monda;
        margin-bottom: 12px;
      }
      .select_width210 {
        width: 120px;
        margin: 0 20px 12px 10px;
      }
      
      .select_width {
        margin: 0 20px 12px 10px;
      }
    }
    .require {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 100px 0 60px;
      justify-content: space-between;
      .req_text1 {
        width: 540px;
        margin-bottom: 30px;
      }
      .req_text2 {
        width: 260px;
        margin-bottom: 30px;
      }
      .req_text3 {
        width: 360px;
        margin-bottom: 30px;
      }
      .text_top {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        font-family: Monda;
        margin-bottom: 20px;
      }
      .text_bottom {
        font-size: 16px;
        font-family: Monda;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .formula {
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 20px;
      font-family: 'MJXTEX-I';
    }
    .fraction {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
    }
    .fraction .numerator {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      border-bottom: 1px solid rgba(255, 255, 255, .6);
      padding: 5px;
    }
    .fraction .denominator {
      padding-top: 2px;
    }
    .product, .sum {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .BandWidth {
    
  }
  
}

@media screen and (max-width: 900px) {
  .BandWidth {
    .page_cont3 {
      .page_cont {
        .cont_table {
          .table_cont {
            tr.theader {
              font-size: 12px;
            }
          }
        }
      }
    }
    .page_cont5 {
      .require {
        .req_text3 {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style>
.el-input__wrapper {
  background: transparent !important;
}
.el-input__inner {
  color: #38EED6 !important;
}
</style>

