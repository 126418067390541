import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'
import Reward from '../views/reward.vue'
import Nft from '../views/nft.vue'
import interNet from '../views/internetCafe.vue'
import gameFi from '../views/gameFi.vue'
import question from '../views/question.vue'
import software from '../views/software.vue'
import blog from '../views/blog.vue'
import blogInfo from '../views/blogInfo.vue'
import faq from '../views/faq.vue'
import bandWidth from '../views/bandWidth.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '@/views/home.vue')
  },
  {
    path: '/node',
    name: 'node',
    component: () => import( /* webpackChunkName: "reward" */ '@/views/reward.vue')
  },
  {
    path: '/nft',
    name: 'nft',
    component: () => import( /* webpackChunkName: "nft" */ '@/views/nft.vue')
  },
  {
    path: '/internet',
    name: 'internet', 
    component: () => import( /* webpackChunkName: "internetCafe" */ '@/views/internetCafe.vue')
  },
  {
    path: '/gamefi',
    name: 'gamefi',
    component: () => import( /* webpackChunkName: "gameFi" */ '@/views/gameFi.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/question.vue')
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('@/views/software.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog.vue')
  },
  {
    path: '/blogInfo',
    name: 'blogInfo',
    component: () => import('@/views/blogInfo.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/faq.vue')
  },
  {
    path: '/bandWidth',
    name: 'bandWidth',
    component: () => import('@/views/bandWidth.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
